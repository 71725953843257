import React, { useEffect } from 'react';

const LiveChat = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//fw-cdn.com/11472500/4137184.js';
        script.chat = 'true';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null
};

export default LiveChat;