import { Link } from "@inertiajs/react";
import { SearchBar } from "./SearchBar";
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useState, useEffect } from "react";
import { RxHamburgerMenu } from "react-icons/rx";

export default function Header({ children, className = "", ...props }) {
    const [visibleRight, setVisibleRight] = useState(false);

    useEffect(() => {
        if (visibleRight) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [visibleRight]);

    return (
        <header className="" style={{ boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)' }}>
            <div {...props} className={'container flex justify-between mx-auto py-5 px-5 2xl:px-0' + className}>
                <div className="flex items-center">
                    <div className="inline-block me-8" ><Link href="/"><img src="/images/logo.webp" className=" max-w-[148px]" alt="FirearmLand Logo" /></Link></div>

                    <div className="items-center hidden lg:flex gap-x-8">
                        <Link href="/">Live Auction</Link>
                        <Link href="/recently-sold">Recently Sold</Link>
                        <Link href="/about-us">About Us</Link>
                        <Link href="/sell-gun">Why Choose Us</Link>
                    </div>
                </div>

                <div className="flex">
                    <SearchBar />

                    <Button className="flex justify-center min-w-8 lg:hidden" onClick={() => setVisibleRight(true)}><RxHamburgerMenu width={24} height={24} /></Button>

                    <div className="hidden ml-2 lg:block">
                        {/* <a href="https://www.cashforarms.com/" className="sm:ms-5 lg:ms-0 hidden sm:flex h-[54px] w-[124px] text-neutral-50 rounded-lg transition-colors bg-maroon hover:bg-maroon-light items-center justify-center" target="_blank">Sell My Gun</a> */}
                        <a href="https://www.cashforarms.com/" className="sm:ms-5 lg:ms-0 hidden sm:flex h-[54px] w-[190px] text-neutral-50 rounded-lg transition-colors bg-maroon hover:bg-maroon-light items-center justify-center" target="_blank">Start Earning Now</a>
                    </div>
                </div>
            </div>

            <Sidebar className="bg-[#000]" visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                <div className="flex flex-col text-white gap-y-8">
                    <div className="inline-block me-8"><img src="/images/logo.webp" className=" max-w-[148px]" style={{'position':'absolute','top':'28px'}} alt="FirearmLand Logo" /></div>

                    <h4 className="text-2xl font-semibold">Menu</h4>
                    <Link href="/">Live Auction</Link>
                    <Link href="/recently-sold">Recently Sold</Link>
                    <Link href="/about-us">About Us</Link>
                    <Link href="/sell-gun">Why Choose Us</Link>
                </div>
                <div className="absolute bottom-[20px] left-[20px] right-[20px] text-center">
                    {/* <a href="https://www.cashforarms.com/" className="px-[20px] py-[15px] text-neutral-50 rounded-lg transition-colors bg-maroon hover:bg-maroon-light items-center justify-center" target="_blank">Sell My Gun</a> */}
                    <a href="https://www.cashforarms.com/" className="px-[20px] py-[15px] text-neutral-50 rounded-lg transition-colors bg-maroon hover:bg-maroon-light items-center justify-center" target="_blank">Start Earning Now</a>
                </div>
            </Sidebar>
        </header>
    );
}