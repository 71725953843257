import Footer from "@/Components/Footer";
import Header from "@/Components/Header";
import React from "react";
import ScrollToTopButton from "@/Components/Common/ScrollToTop";
import LiveChat from "@/Hooks/LiveChat"


export default function SiteLayout({ children, ...props }) {

    return <>

        <div className="min-h-svh flex flex-col">
            <Header />

            <main className="flex-grow">
                {children}
            </main>

            <Footer />
            <LiveChat />
            <ScrollToTopButton />
        </div>

    </>
}