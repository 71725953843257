import { Link } from "@inertiajs/react";
import { Join } from "./Common/Join";

export default function Footer() {
    return (
        <footer className="bg-dark-gray text-neutral-50 pb-6 lg:pb-[60px] lg:pt-[117px]">
            <div className="container px-5 mx-auto 2xl:px-0">
                <div className="justify-between md:flex md:flex-col lg:flex-row">
                    <div className="justify-center hidden lg:flex"><img src="/images/logo-footer.webp" className="inline-block h-[144px]" alt="FirearmLand Logo Symbol" /></div>

                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-x-[88px] gap-y-4 mt-6 lg:mt-10 lg:text-center lg:text-left lg:mt-0">
                        <Link href="/">Active Listing</Link>
                        <Link href="/recently-sold">Recently Sold</Link>
                        <Link href="/terms">Terms and Conditions</Link>
                        <Link href="/about-us">About Us</Link>
                    </div>

                    <div className="hidden mt-10 text-center lg:block lg:mt-0 lg:text-left">
                        <p className="mb-6 text-xl">Lock 'n Load Your Inbox</p>
                        <p>Rare finds, VIP giveaways, and electrifying video content. <br />All delivered weekly directly to your inbox, FOR FREE.</p>

                        <div className="flex justify-center mt-8">
                            <Join w="490px" />
                        </div>

                        <p className="mt-4 text-sm">Your adventure starts now</p>
                    </div>
                </div>

                <div className="mt-6 lg:mt-20">
                    <p className="text-sm lg:text-right lg:text-center">© Copyright 2023 Firearmland LLC</p>
                </div>
            </div>


        </footer>
    )
}